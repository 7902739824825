<template>
    <div>
      <div v-if="!isLoading">
          <ul v-if="typeof sources !== 'undefined' && sources.length" class="list-group m-b-15">
              <li
                  v-for="source in sources"
                  :key="source.id"
                  class="list-group-item"
              >
                  {{source.title}}
              </li>
          </ul>
          <b-pagination v-if="totalSources > payload.limit && !isLoading"
            v-model="currentPage"
            :total-rows="totalSources"
            :per-page="payload.limit"
            aria-controls="my-table"
        ></b-pagination>
      </div>
      <div v-else>
        <b-skeleton width="85%"></b-skeleton>
        <b-skeleton width="55%"></b-skeleton>
        <b-skeleton width="70%"></b-skeleton>
        <b-skeleton width="85%"></b-skeleton>
        <b-skeleton width="55%"></b-skeleton>
        <b-skeleton width="70%"></b-skeleton>
      </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
    export default {
        data() {
            return {
                isLoading: false,
                payload: {
                    filters: {
                        has_source_group: false,
                    },
                    limit: 300,
                    offset: 0
                },
                currentPage: 1
            };
        },
        computed: {
            ...mapGetters('source_groups', ['sources', 'totalSources']),
        },
        methods: {
            loadSources() {
                this.isLoading = true;
                this.$store.dispatch('source_groups/getSources',this.payload).finally(() => {
                    this.isLoading = false;
                });
            }
        },
        mounted() {
            this.$store.dispatch('source_groups/clearSources');
            this.loadSources();
        },
        watch: {
            currentPage() {
                this.payload.offset = (this.currentPage - 1) * this.payload.limit;
                this.loadSources();
            }
        }
    }
</script>
<style lang="scss">/*
    .card {
        min-width: 400px;
        min-height: 400px;
    }
    h3 {
        font-weight: 700;
    }
*/</style>
