<template>
  <div>
    <div v-if="!isLoading">
      <ul v-if="typeof services !== 'undefined' && services.length" class="list-group m-b-30">
        <li
            v-for="service in services"
            :key="service.id"
            class="list-group-item"
        >
          {{service.name}}
        </li>
      </ul>
      <b-pagination v-if="totalServices > payload.limit && !isLoading"
        v-model="currentPage"
        :total-rows="totalServices"
        :per-page="payload.limit"
        aria-controls="my-table"
      ></b-pagination>
    </div>
    <div v-else>
      <b-skeleton width="85%"></b-skeleton>
      <b-skeleton width="55%"></b-skeleton>
      <b-skeleton width="70%"></b-skeleton>
      <b-skeleton width="85%"></b-skeleton>
      <b-skeleton width="55%"></b-skeleton>
      <b-skeleton width="70%"></b-skeleton>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ModalServices",
  data() {
    return {
      isLoading: false,
      currentPage: 1,
      payload: {
        filters: {
          has_service_group: false,
        },
        limit: 300,
        offset: 0
      }
    };
  },
  computed: {
    ...mapGetters('service_groups', ['services', 'totalServices']),
  },
  methods: {
    loadServices() {
      this.isLoading = true;
      this.$store.dispatch('service_groups/getServices', this.payload).finally(() => {
        this.isLoading = false;
      });
    }
  },
  mounted() {
    this.$store.dispatch('source_groups/clearSources');
    this.loadServices();
  },
  watch: {
    currentPage() {
      this.payload.offset = (this.currentPage - 1) * this.payload.limit;
      this.loadServices();
    }
  }
}
</script>

<style scoped>

</style>